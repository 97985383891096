<template>
  <div class="editor">
    <div class="title">
      <img src="@/assets/icons/user-yellow.svg" alt="">
      <span>{{ $t('ai_editor')/*設定人*/ }}</span>
    </div>
    <div class="group-name">
      <img src="@/assets/icons/Group-yellow.svg" alt="">
      <span>{{ groupName }}</span>
    </div>
    <div class="user-name">
      {{ userName }}({{ accountId }})
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'Editor',
  data() {
    return {
      // groupName: 'GroupName',
      // userName: 'UserName',
      // accountId: 'AccountId',
    }
  },
  computed: {
    ...mapState(['userList', 'groupList']),
    ...mapGetters('aiboxFr', ['currentSetting']),
    user() {
      return this.userList.find(user => user.index === Number(this.currentSetting.editorUserId))
    },
    groupName() {
      return this.groupList.find(group => group.id === this.user.groupId).name
    },
    userName() {
      return this.user.info.name
    },
    accountId() {
      return this.user.id
    },
  }
}
</script>

<style lang="scss" scoped>
.editor {
  width: 100%;
  height: 100%;
  padding: 12px;
  font-size: 24px;
  line-height: 36px;
  background: #4A5C7833;
  border-radius: 8px;

  .title {
    display: flex;
    align-items: center;
    font-size: 24px;
    line-height: 36px;
    font-weight: bold;
    color: #FFE99F;
    img {
      margin-right: 8px;
    }
  }

  .group-name {
    display: flex;
    align-items: center;
    color: #FFC600;
    font-weight: bold;
    img {
      margin-right: 8px;
    }
  }

  .user-name {
    color: #ffffff;
  }
}
</style>